import React, { useRef, useState } from "react";
import leftClick from "../../../assets/pics/left-click.png";
import img1 from "../../../assets/pics/360/red/Boucle_Red_01.jpg";
import img2 from "../../../assets/pics/360/red/Boucle_Red_02.jpg";
import img3 from "../../../assets/pics/360/red/Boucle_Red_03.jpg";
import img4 from "../../../assets/pics/360/red/Boucle_Red_04.jpg";
import img5 from "../../../assets/pics/360/red/Boucle_Red_05.jpg";
import img6 from "../../../assets/pics/360/red/Boucle_Red_06.jpg";
import img7 from "../../../assets/pics/360/red/Boucle_Red_07.jpg";
import img8 from "../../../assets/pics/360/red/Boucle_Red_08.jpg";
import img9 from "../../../assets/pics/360/red/Boucle_Red_09.jpg";
import img10 from "../../../assets/pics/360/red/Boucle_Red_10.jpg";
import img11 from "../../../assets/pics/360/red/Boucle_Red_11.jpg";
import img12 from "../../../assets/pics/360/red/Boucle_Red_12.jpg";
import img13 from "../../../assets/pics/360/red/Boucle_Red_13.jpg";
import img14 from "../../../assets/pics/360/red/Boucle_Red_14.jpg";
import img15 from "../../../assets/pics/360/red/Boucle_Red_15.jpg";
import img16 from "../../../assets/pics/360/red/Boucle_Red_16.jpg";
import img17 from "../../../assets/pics/360/red/Boucle_Red_17.jpg";
import img18 from "../../../assets/pics/360/red/Boucle_Red_18.jpg";
import img19 from "../../../assets/pics/360/red/Boucle_Red_19.jpg";
import img20 from "../../../assets/pics/360/red/Boucle_Red_20.jpg";
import img21 from "../../../assets/pics/360/red/Boucle_Red_21.jpg";
import img22 from "../../../assets/pics/360/red/Boucle_Red_22.jpg";
import img23 from "../../../assets/pics/360/red/Boucle_Red_23.jpg";
import img24 from "../../../assets/pics/360/red/Boucle_Red_24.jpg";
import img25 from "../../../assets/pics/360/red/Boucle_Red_25.jpg";
import img26 from "../../../assets/pics/360/red/Boucle_Red_26.jpg";
import img27 from "../../../assets/pics/360/red/Boucle_Red_27.jpg";
import img28 from "../../../assets/pics/360/red/Boucle_Red_28.jpg";
import img29 from "../../../assets/pics/360/red/Boucle_Red_29.jpg";
import img30 from "../../../assets/pics/360/red/Boucle_Red_30.jpg";
import "./style.scss";

const RedSofa = ({ closeModal, mouseIsDown, openedModal, getModalDetails }) => {
  const ref = useRef(null);
  const refPosition = useRef(0);
  let [counter, setCounter] = useState(1);

  function handleMouseMove(e) {
    if (!mouseIsDown) return;
    let threshold = Math.floor(ref.current.offsetWidth / 30);

    if (refPosition.current === 0) {
      refPosition.current = e.clientX;
    }

    if (e.clientX >= refPosition.current + threshold) {
      refPosition.current = e.clientX;

      if (counter === 30) {
        setCounter(1);
      } else {
        setCounter((prev) => prev + 1);
      }
    }

    if (e.clientX <= refPosition.current - threshold) {
      refPosition.current = e.clientX;

      if (counter === 1) {
        setCounter(30);
      } else {
        setCounter((prev) => prev - 1);
      }
    }
  }

  return (
    <div onMouseMove={(ev) => handleMouseMove(ev)} id='RedSofa'>
      <div className='modalBg'></div>
      <div className={`${mouseIsDown ? "canHover" : ""} bodyBox`}>
        <div className='viewerTitle'>
          <div className='one'>
            <p
              className='bordered screenshot'
              style={{ cursor: "not-allowed" }}
            >
              <i className='fa-solid fa-users-viewfinder'></i>View in your space
            </p>
          </div>
          <p className='tit one'>Apto 3D Viewer</p>
          <i
            onClick={() => closeModal()}
            className='fa-solid fa-xmark closeBtn one'
          ></i>
        </div>

        <div className='smallTitle'>
          <img src={leftClick} alt='' />
          <p>Click and drag to rotate.</p>
        </div>
        {/* <i
          onClick={() => closeModal()}
          className='fa-solid fa-xmark closeBtn'
        ></i> */}
        <div ref={ref} className={`${mouseIsDown ? "canHover" : ""} card`}>
          <img
            className={counter === 1 ? "show_1" : "hide_1"}
            src={img1}
            alt=''
          />
          <img
            className={counter === 2 ? "show_1" : "hide_1"}
            src={img2}
            alt=''
          />
          <img
            className={counter === 3 ? "show_1" : "hide_1"}
            src={img3}
            alt=''
          />
          <img
            className={counter === 4 ? "show_1" : "hide_1"}
            src={img4}
            alt=''
          />
          <img
            className={counter === 5 ? "show_1" : "hide_1"}
            src={img5}
            alt=''
          />
          <img
            className={counter === 6 ? "show_1" : "hide_1"}
            src={img6}
            alt=''
          />
          <img
            className={counter === 7 ? "show_1" : "hide_1"}
            src={img7}
            alt=''
          />
          <img
            className={counter === 8 ? "show_1" : "hide_1"}
            src={img8}
            alt=''
          />
          <img
            className={counter === 9 ? "show_1" : "hide_1"}
            src={img9}
            alt=''
          />
          <img
            className={counter === 10 ? "show_1" : "hide_1"}
            src={img10}
            alt=''
          />
          <img
            className={counter === 11 ? "show_1" : "hide_1"}
            src={img11}
            alt=''
          />
          <img
            className={counter === 12 ? "show_1" : "hide_1"}
            src={img12}
            alt=''
          />
          <img
            className={counter === 13 ? "show_1" : "hide_1"}
            src={img13}
            alt=''
          />
          <img
            className={counter === 14 ? "show_1" : "hide_1"}
            src={img14}
            alt=''
          />
          <img
            className={counter === 15 ? "show_1" : "hide_1"}
            src={img15}
            alt=''
          />
          <img
            className={counter === 16 ? "show_1" : "hide_1"}
            src={img16}
            alt=''
          />
          <img
            className={counter === 17 ? "show_1" : "hide_1"}
            src={img17}
            alt=''
          />
          <img
            className={counter === 18 ? "show_1" : "hide_1"}
            src={img18}
            alt=''
          />
          <img
            className={counter === 19 ? "show_1" : "hide_1"}
            src={img19}
            alt=''
          />
          <img
            className={counter === 20 ? "show_1" : "hide_1"}
            src={img20}
            alt=''
          />
          <img
            className={counter === 21 ? "show_1" : "hide_1"}
            src={img21}
            alt=''
          />
          <img
            className={counter === 22 ? "show_1" : "hide_1"}
            src={img22}
            alt=''
          />
          <img
            className={counter === 23 ? "show_1" : "hide_1"}
            src={img23}
            alt=''
          />
          <img
            className={counter === 24 ? "show_1" : "hide_1"}
            src={img24}
            alt=''
          />
          <img
            className={counter === 25 ? "show_1" : "hide_1"}
            src={img25}
            alt=''
          />
          <img
            className={counter === 26 ? "show_1" : "hide_1"}
            src={img26}
            alt=''
          />
          <img
            className={counter === 27 ? "show_1" : "hide_1"}
            src={img27}
            alt=''
          />
          <img
            className={counter === 28 ? "show_1" : "hide_1"}
            src={img28}
            alt=''
          />
          <img
            className={counter === 29 ? "show_1" : "hide_1"}
            src={img29}
            alt=''
          />
          <img
            className={counter === 30 ? "show_1" : "hide_1"}
            src={img30}
            alt=''
          />
        </div>
      </div>
    </div>
  );
};

export default RedSofa;
