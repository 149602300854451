import React, { useRef, useState } from "react";
import leftClick from "../../../assets/pics/left-click.png";
import dimensions from "../../../assets/pics/Sofa_Dimensions.png";
import qrCode from "../../../assets/pics/qrcode_apto3d.com.png";
import img1 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Black/PaulSofa_VelvetGreen_Orange_Black_1.jpg";
import img2 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Black/PaulSofa_VelvetGreen_Orange_Black_2.jpg";
import img3 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Black/PaulSofa_VelvetGreen_Orange_Black_3.jpg";
import img4 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Black/PaulSofa_VelvetGreen_Orange_Black_4.jpg";
import img5 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Black/PaulSofa_VelvetGreen_Orange_Black_5.jpg";
import img6 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Black/PaulSofa_VelvetGreen_Orange_Black_6.jpg";
import img7 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Black/PaulSofa_VelvetGreen_Orange_Black_7.jpg";
import img8 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Black/PaulSofa_VelvetGreen_Orange_Black_8.jpg";
import img9 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Black/PaulSofa_VelvetGreen_Orange_Black_9.jpg";
import img10 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Black/PaulSofa_VelvetGreen_Orange_Black_10.jpg";
import img11 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Black/PaulSofa_VelvetGreen_Orange_Black_11.jpg";
import img12 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Black/PaulSofa_VelvetGreen_Orange_Black_12.jpg";
import img13 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Black/PaulSofa_VelvetGreen_Orange_Black_13.jpg";
import img14 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Black/PaulSofa_VelvetGreen_Orange_Black_14.jpg";
import img15 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Black/PaulSofa_VelvetGreen_Orange_Black_15.jpg";
import img16 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Black/PaulSofa_VelvetGreen_Orange_Black_16.jpg";
import img17 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Black/PaulSofa_VelvetGreen_Orange_Black_17.jpg";
import img18 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Black/PaulSofa_VelvetGreen_Orange_Black_18.jpg";
import img19 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Black/PaulSofa_VelvetGreen_Orange_Black_19.jpg";
import img20 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Black/PaulSofa_VelvetGreen_Orange_Black_20.jpg";
import img21 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Black/PaulSofa_VelvetGreen_Orange_Black_21.jpg";
import img22 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Black/PaulSofa_VelvetGreen_Orange_Black_22.jpg";
import img23 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Black/PaulSofa_VelvetGreen_Orange_Black_23.jpg";
import img24 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Black/PaulSofa_VelvetGreen_Orange_Black_24.jpg";
import img25 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Black/PaulSofa_VelvetGreen_Orange_Black_25.jpg";
import img26 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Black/PaulSofa_VelvetGreen_Orange_Black_26.jpg";
import img27 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Black/PaulSofa_VelvetGreen_Orange_Black_27.jpg";
import img28 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Black/PaulSofa_VelvetGreen_Orange_Black_28.jpg";
import img29 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Black/PaulSofa_VelvetGreen_Orange_Black_29.jpg";
import img30 from "../../../assets/pics/360/PaulSofa_VelvetGreen_Orange_Black/PaulSofa_VelvetGreen_Orange_Black_30.jpg";
import "./style.scss";

const PaulSofa_VelvetGreen_Orange_Black = ({
  closeModal,
  mouseIsDown,
  setOpenedModal,
  openedModal,
  getModalDetails,
}) => {
  const ref = useRef(null);
  const refPosition = useRef(0);
  let [counter, setCounter] = useState(1);
  let [openViewInSpace, setOpenViewInSpace] = useState(false);
  const values = getModalDetails(openedModal);

  function handleMouseMove(e) {
    if (!mouseIsDown) return;
    let threshold = Math.floor(ref.current.offsetWidth / 30);

    if (refPosition.current === 0) {
      refPosition.current = e.clientX;
    }

    if (e.clientX >= refPosition.current + threshold) {
      refPosition.current = e.clientX;

      if (counter === 30) {
        setCounter(1);
      } else {
        setCounter((prev) => prev + 1);
      }
    }

    if (e.clientX <= refPosition.current - threshold) {
      refPosition.current = e.clientX;

      if (counter === 1) {
        setCounter(30);
      } else {
        setCounter((prev) => prev - 1);
      }
    }
  }

  return (
    <div onMouseMove={(ev) => handleMouseMove(ev)} id='RedSofa'>
      <div className='modalBg'></div>
      <div className={`${mouseIsDown ? "canHover" : ""} bodyBox`}>
        <div className='viewerTitle'>
          <div className='one'>
            <p
              onClick={() => setOpenViewInSpace(!openViewInSpace)}
              className='bordered screenshot'
            >
              <i className='fa-solid fa-users-viewfinder'></i>View in your space
            </p>
          </div>
          <p className='tit one'>Apto 3D Viewer</p>
          <i
            onClick={() => closeModal()}
            className='fa-solid fa-xmark closeBtn one'
          ></i>
        </div>

        <div className='right'>
          <div className='blackBg'>
            <div className='mainArea'>
              <div className='summary'>
                <span>Summary</span>
              </div>
              <div className='boxItem'>
                <div>
                  <span className='title'>Name</span>
                  <span className='title'>Price</span>
                </div>
                <div>
                  <span>Sofa</span>
                  <span>$1450</span>
                </div>
              </div>
              <div className='specItem'>
                <p className='title'>Configuration details:</p>
                <div className='specItemInner'>
                  <p>Fabric</p>
                  <p className='val'>{values.fabric}</p>
                </div>
                <div className='specItemInner'>
                  <p>Piping</p>
                  <p className='val'>{values.piping}</p>
                </div>
                <div className='specItemInner'>
                  <p>Leg</p>
                  <p className='val'>{values.leg}</p>
                </div>
              </div>
              <div className='picItem'>
                <p className='title'>Dimensions:</p>
                <img src={dimensions} alt='..' />
              </div>
            </div>
            <div className='btnCart'>
              <p>Add to cart</p>
            </div>
          </div>
        </div>

        {openViewInSpace && (
          <div className='left'>
            <div className='whiteBg'>
              <div className='close'>
                <i
                  onClick={() => setOpenViewInSpace(false)}
                  className='fa-solid fa-chevron-up'
                ></i>
              </div>
              <div className='main'>
                <div>
                  <p className='title'>View in your space</p>
                  <p className='text'>
                    Scan the QR code with the camera app on your mobile device.
                  </p>
                  <img src={qrCode} alt='..' />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className='smallTitle'>
          <img src={leftClick} alt='' />
          <p>Click and drag to rotate.</p>
        </div>
        {/* <i
          onClick={() => closeModal()}
          className='fa-solid fa-xmark closeBtn'
        ></i> */}
        <div ref={ref} className={`${mouseIsDown ? "canHover" : ""} card`}>
          <img
            className={counter === 1 ? "show_1" : "hide_1"}
            src={img1}
            alt=''
          />
          <img
            className={counter === 2 ? "show_1" : "hide_1"}
            src={img2}
            alt=''
          />
          <img
            className={counter === 3 ? "show_1" : "hide_1"}
            src={img3}
            alt=''
          />
          <img
            className={counter === 4 ? "show_1" : "hide_1"}
            src={img4}
            alt=''
          />
          <img
            className={counter === 5 ? "show_1" : "hide_1"}
            src={img5}
            alt=''
          />
          <img
            className={counter === 6 ? "show_1" : "hide_1"}
            src={img6}
            alt=''
          />
          <img
            className={counter === 7 ? "show_1" : "hide_1"}
            src={img7}
            alt=''
          />
          <img
            className={counter === 8 ? "show_1" : "hide_1"}
            src={img8}
            alt=''
          />
          <img
            className={counter === 9 ? "show_1" : "hide_1"}
            src={img9}
            alt=''
          />
          <img
            className={counter === 10 ? "show_1" : "hide_1"}
            src={img10}
            alt=''
          />
          <img
            className={counter === 11 ? "show_1" : "hide_1"}
            src={img11}
            alt=''
          />
          <img
            className={counter === 12 ? "show_1" : "hide_1"}
            src={img12}
            alt=''
          />
          <img
            className={counter === 13 ? "show_1" : "hide_1"}
            src={img13}
            alt=''
          />
          <img
            className={counter === 14 ? "show_1" : "hide_1"}
            src={img14}
            alt=''
          />
          <img
            className={counter === 15 ? "show_1" : "hide_1"}
            src={img15}
            alt=''
          />
          <img
            className={counter === 16 ? "show_1" : "hide_1"}
            src={img16}
            alt=''
          />
          <img
            className={counter === 17 ? "show_1" : "hide_1"}
            src={img17}
            alt=''
          />
          <img
            className={counter === 18 ? "show_1" : "hide_1"}
            src={img18}
            alt=''
          />
          <img
            className={counter === 19 ? "show_1" : "hide_1"}
            src={img19}
            alt=''
          />
          <img
            className={counter === 20 ? "show_1" : "hide_1"}
            src={img20}
            alt=''
          />
          <img
            className={counter === 21 ? "show_1" : "hide_1"}
            src={img21}
            alt=''
          />
          <img
            className={counter === 22 ? "show_1" : "hide_1"}
            src={img22}
            alt=''
          />
          <img
            className={counter === 23 ? "show_1" : "hide_1"}
            src={img23}
            alt=''
          />
          <img
            className={counter === 24 ? "show_1" : "hide_1"}
            src={img24}
            alt=''
          />
          <img
            className={counter === 25 ? "show_1" : "hide_1"}
            src={img25}
            alt=''
          />
          <img
            className={counter === 26 ? "show_1" : "hide_1"}
            src={img26}
            alt=''
          />
          <img
            className={counter === 27 ? "show_1" : "hide_1"}
            src={img27}
            alt=''
          />
          <img
            className={counter === 28 ? "show_1" : "hide_1"}
            src={img28}
            alt=''
          />
          <img
            className={counter === 29 ? "show_1" : "hide_1"}
            src={img29}
            alt=''
          />
          <img
            className={counter === 30 ? "show_1" : "hide_1"}
            src={img30}
            alt=''
          />
        </div>
      </div>
    </div>
  );
};

export default PaulSofa_VelvetGreen_Orange_Black;
