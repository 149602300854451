import React, { useRef, useState } from "react";
import leftClick from "../../../assets/pics/left-click.png";
import imgB1 from "../../../assets/pics/360/blue/1.jpg";
import imgB2 from "../../../assets/pics/360/blue/2.jpg";
import imgB3 from "../../../assets/pics/360/blue/3.jpg";
import imgB4 from "../../../assets/pics/360/blue/4.jpg";
import imgB5 from "../../../assets/pics/360/blue/5.jpg";
import imgB6 from "../../../assets/pics/360/blue/6.jpg";
import imgB7 from "../../../assets/pics/360/blue/7.jpg";
import imgB8 from "../../../assets/pics/360/blue/8.jpg";
import imgB9 from "../../../assets/pics/360/blue/9.jpg";
import imgB10 from "../../../assets/pics/360/blue/10.jpg";
import imgB11 from "../../../assets/pics/360/blue/11.jpg";
import imgB12 from "../../../assets/pics/360/blue/12.jpg";
import imgB13 from "../../../assets/pics/360/blue/13.jpg";
import imgB14 from "../../../assets/pics/360/blue/14.jpg";
import imgB15 from "../../../assets/pics/360/blue/15.jpg";
import imgB16 from "../../../assets/pics/360/blue/16.jpg";
import imgB17 from "../../../assets/pics/360/blue/17.jpg";
import imgB18 from "../../../assets/pics/360/blue/18.jpg";
import imgB19 from "../../../assets/pics/360/blue/19.jpg";
import imgB20 from "../../../assets/pics/360/blue/20.jpg";
import imgB21 from "../../../assets/pics/360/blue/21.jpg";
import imgB22 from "../../../assets/pics/360/blue/22.jpg";
import imgB23 from "../../../assets/pics/360/blue/23.jpg";
import imgB24 from "../../../assets/pics/360/blue/24.jpg";
import imgB25 from "../../../assets/pics/360/blue/25.jpg";
import imgB26 from "../../../assets/pics/360/blue/26.jpg";
import imgB27 from "../../../assets/pics/360/blue/27.jpg";
import imgB28 from "../../../assets/pics/360/blue/28.jpg";
import imgB29 from "../../../assets/pics/360/blue/29.jpg";
import imgB30 from "../../../assets/pics/360/blue/30.jpg";
import "./style.scss";

const BlueSofa = ({ closeModal, mouseIsDown }) => {
  const ref = useRef(null);
  const refPosition = useRef(0);
  let [counter, setCounter] = useState(1);

  function handleMouseMove(e) {
    if (!mouseIsDown) return;
    let threshold = Math.floor(ref.current.offsetWidth / 30);

    if (refPosition.current === 0) {
      refPosition.current = e.clientX;
    }

    if (e.clientX >= refPosition.current + threshold) {
      refPosition.current = e.clientX;

      if (counter === 30) {
        setCounter(1);
      } else {
        setCounter((prev) => prev + 1);
      }
    }

    if (e.clientX <= refPosition.current - threshold) {
      refPosition.current = e.clientX;

      if (counter === 1) {
        setCounter(30);
      } else {
        setCounter((prev) => prev - 1);
      }
    }
  }

  return (
    <div onMouseMove={(ev) => handleMouseMove(ev)} id='RedSofa'>
      <div className='modalBg'></div>
      <div className={`${mouseIsDown ? "canHover" : ""} bodyBox`}>
        <div className='viewerTitle'>
          <div className='one'>
            <p
              className='bordered screenshot'
              style={{ cursor: "not-allowed" }}
            >
              <i className='fa-solid fa-users-viewfinder'></i>View in your space
            </p>
          </div>
          <p className='tit one'>Apto 3D Viewer</p>
          <i
            onClick={() => closeModal()}
            className='fa-solid fa-xmark closeBtn one'
          ></i>
        </div>

        <div className='smallTitle'>
          <img src={leftClick} alt='' />
          <p>Click and drag to rotate.</p>
        </div>
        {/* <i
          onClick={() => closeModal()}
          className='fa-solid fa-xmark closeBtn'
        ></i> */}
        <div ref={ref} className={`${mouseIsDown ? "canHover" : ""} card`}>
          <img
            className={counter === 1 ? "show_1" : "hide_1"}
            src={imgB1}
            alt=''
          />
          <img
            className={counter === 2 ? "show_1" : "hide_1"}
            src={imgB2}
            alt=''
          />
          <img
            className={counter === 3 ? "show_1" : "hide_1"}
            src={imgB3}
            alt=''
          />
          <img
            className={counter === 4 ? "show_1" : "hide_1"}
            src={imgB4}
            alt=''
          />
          <img
            className={counter === 5 ? "show_1" : "hide_1"}
            src={imgB5}
            alt=''
          />
          <img
            className={counter === 6 ? "show_1" : "hide_1"}
            src={imgB6}
            alt=''
          />
          <img
            className={counter === 7 ? "show_1" : "hide_1"}
            src={imgB7}
            alt=''
          />
          <img
            className={counter === 8 ? "show_1" : "hide_1"}
            src={imgB8}
            alt=''
          />
          <img
            className={counter === 9 ? "show_1" : "hide_1"}
            src={imgB9}
            alt=''
          />
          <img
            className={counter === 10 ? "show_1" : "hide_1"}
            src={imgB10}
            alt=''
          />
          <img
            className={counter === 11 ? "show_1" : "hide_1"}
            src={imgB11}
            alt=''
          />
          <img
            className={counter === 12 ? "show_1" : "hide_1"}
            src={imgB12}
            alt=''
          />
          <img
            className={counter === 13 ? "show_1" : "hide_1"}
            src={imgB13}
            alt=''
          />
          <img
            className={counter === 14 ? "show_1" : "hide_1"}
            src={imgB14}
            alt=''
          />
          <img
            className={counter === 15 ? "show_1" : "hide_1"}
            src={imgB15}
            alt=''
          />
          <img
            className={counter === 16 ? "show_1" : "hide_1"}
            src={imgB16}
            alt=''
          />
          <img
            className={counter === 17 ? "show_1" : "hide_1"}
            src={imgB17}
            alt=''
          />
          <img
            className={counter === 18 ? "show_1" : "hide_1"}
            src={imgB18}
            alt=''
          />
          <img
            className={counter === 19 ? "show_1" : "hide_1"}
            src={imgB19}
            alt=''
          />
          <img
            className={counter === 20 ? "show_1" : "hide_1"}
            src={imgB20}
            alt=''
          />
          <img
            className={counter === 21 ? "show_1" : "hide_1"}
            src={imgB21}
            alt=''
          />
          <img
            className={counter === 22 ? "show_1" : "hide_1"}
            src={imgB22}
            alt=''
          />
          <img
            className={counter === 23 ? "show_1" : "hide_1"}
            src={imgB23}
            alt=''
          />
          <img
            className={counter === 24 ? "show_1" : "hide_1"}
            src={imgB24}
            alt=''
          />
          <img
            className={counter === 25 ? "show_1" : "hide_1"}
            src={imgB25}
            alt=''
          />
          <img
            className={counter === 26 ? "show_1" : "hide_1"}
            src={imgB26}
            alt=''
          />
          <img
            className={counter === 27 ? "show_1" : "hide_1"}
            src={imgB27}
            alt=''
          />
          <img
            className={counter === 28 ? "show_1" : "hide_1"}
            src={imgB28}
            alt=''
          />
          <img
            className={counter === 29 ? "show_1" : "hide_1"}
            src={imgB29}
            alt=''
          />
          <img
            className={counter === 30 ? "show_1" : "hide_1"}
            src={imgB30}
            alt=''
          />
        </div>
      </div>
    </div>
  );
};

export default BlueSofa;
