import React from "react";
import { Link } from "react-scroll";
import { Link as ReactLink } from "react-router-dom";
import "./style.scss";

const PrimaryButton = ({
  label,
  className,
  to,
  offset,
  hrefTo,
  reactLink,
  white,
  blue,
  blueGradient,
}) => {
  if (reactLink) {
    return (
      <ReactLink
        to={reactLink}
        className={`linkBtn ${white && "whiteBtn"} ${
          blue && "purpleGradientBtn"
        } ${blueGradient && "blueGradientBtn"}`}
      >
        {label}
      </ReactLink>
    );
  }

  if (hrefTo) {
    return (
      <div id='PrimaryButton' className={`${className}`}>
        <a href={hrefTo} className={`btn aaa`}>
          {label}
        </a>
      </div>
    );
  }

  return to ? (
    <div id='PrimaryButton' className={`${className}`}>
      <Link to={to} spy={true} smooth={true} duration={700} offset={offset}>
        <span className={`btn`}>{label}</span>
      </Link>
    </div>
  ) : (
    <div id='PrimaryButton' className={`${className}`}>
      <span className={`btn`}>{label}</span>
    </div>
  );
};

export default PrimaryButton;
