import React from "react";
import PrimaryButton from "../../atoms/PrimaryButton";

import "./style.scss";

const Outsourcing = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id='Outsourcing'>
      <div id='top_main' className='main'>
        <h1>Apto outsourcing services</h1>
        <p>
          Flexible, Cost-Effective Solutions for Your 3D Visualization Needs.{" "}
          <br />
          Streamline Your Business with Expert 3D Visualization Talent.
        </p>
      </div>

      <div className='sub-main'>
        <h2>Apto makes business better.</h2>
        <p>
          Welcome to Apto Employee Outsourcing! <br /> We understand that
          managing in-house 3D visualization talent can be time-consuming and
          expensive. <br />
          That's why we offer a flexible, cost-effective solution to help you
          access top-notch 3D visualization experts when you need them, <br />{" "}
          without the hassle of traditional hiring and employee management.
        </p>
      </div>

      <div id='why_us' className='container'>
        <div className='why-us'>
          <h2>
            Why choose Apto <br />
            outsourcing services?
          </h2>
          <div className='services-wrapper'>
            <div className='services'>
              <div className='service'>
                <h3 className='title'>No Taxes or Hidden Fees.</h3>
                <p className='text'>
                  With Apto, you only pay for the services you need without
                  worrying about taxes or additional charges. Our transparent
                  pricing structure ensures you get the most value for your
                  money.
                </p>
              </div>
              <div className='service'>
                <h3 className='title'>Deadline Adherence.</h3>
                <p className='text'>
                  At Apto, we understand the importance of meeting deadlines.
                  Our extensive talent pool and efficient project management
                  processes ensure that your 3D visuali 3D visualization
                  projects are delivered on time, every time.
                </p>
              </div>
              <div className='service'>
                <h3 className='title'>Flexible Hiring Options.</h3>
                <p className='text'>
                  Whether you need assistance with a single project or ongoing
                  support, our employee outsourcing services allow you to hire
                  skilled 3D visualization artists on a monthly monthly basis.
                  Scale your team up or down as needed to meet your project
                  demands.
                </p>
              </div>
              <div className='service'>
                <h3 className='title'>Seamless Integration.</h3>
                <p className='text'>
                  Our 3D visualization experts easily integrate into your
                  existing workflow, allowing you to maintain control over your
                  projects while benefiting from our specialized expertise.
                </p>
              </div>
              <div className='service'>
                <h3 className='title'>Guaranteed Quality.</h3>
                <p className='text'>
                  We stand by the quality of our work. If you're not satisfied
                  with the results, we'll make it right. Our team of
                  professionals is dedicated to ensuring your complete
                  satisfaction, and we'll work with you until your vision is
                  brought to life.
                </p>
              </div>
            </div>
            <div className='bottomTextBtn'>
              <h2 className='sub-title'>
                Anythingʼs possible <br /> with Apto.
              </h2>
              <PrimaryButton
                className={"moveLeftBtn"}
                label='Get started'
                hrefTo='mailto: outsource@apto3d.com'
              />
            </div>
          </div>
        </div>
      </div>

      <div className='container'>
        <div id='how_it_works' className='why-us'>
          <h2>
            How employee <br />
            outsourcing works?
          </h2>
          <div className='services-wrapper'>
            <div className='services'>
              <div className='service'>
                <h3 className='title'>
                  Step 1: <br />
                  Contact Us.
                </h3>
                <p className='text'>
                  Get in touch with our team to discuss your project
                  requirements and goals. We'll help you determine the best
                  outsourcing solution for your needs.
                </p>
              </div>
              <div className='service'>
                <h3 className='title'>
                  Step 2: <br />
                  Select Your Talent & Free Test Project.
                </h3>
                <p className='text'>
                  Browse our pool of skilled 3D visualization artists and choose
                  the right professionals for your project. Our team will help
                  you match your requirements with the perfect talent. To ensure
                  you've made the right choice, we offer one free test project,
                  allowing your chosen artist to showcase their skills and
                  demonstrate their ability to meet your project's demands.
                </p>
              </div>
              <div className='service'>
                <h3 className='title'>
                  Step 3: <br />
                  Collaborate & Create.
                </h3>
                <p className='text'>
                  Work closely with your dedicated 3D visualization experts to
                  bring your projects to life. With Apto, you'll enjoy seamless
                  collaboration and outstanding results.
                </p>
              </div>
              <div className='service'>
                <h3 className='title'>
                  Step 4: <br />
                  Review & Revise.
                </h3>
                <p className='text'>
                  We're committed to delivering exceptional 3D visuals.If you
                  have any concerns or issues, we'll work with you to make any
                  necessary revisions, ensuring your complete satisfaction.
                </p>
              </div>
              <div className='service'>
                <h3 className='title'>
                  Step 5: <br />
                  Complete Your Project.
                </h3>
                <p className='text'>
                  Once your 3D visualization project is complete, you can choose
                  to continue working with our talented artists or end the
                  contract as needed. With Apto, you're always in control.
                </p>
              </div>
            </div>
            <div className='bottomTextBtn'>
              <h2 className='sub-title'>
                At your service. <br /> Every step of the way.
              </h2>
              <PrimaryButton
                className={"moveLeftBtn"}
                label='Learn more'
                hrefTo='mailto: outsource@apto3d.com'
              />
            </div>
          </div>
        </div>
      </div>

      <div className='ending'>
        <h2>Ready to Transform Your 3D Visualization Projects?</h2>
        <p>
          Contact Apto Employee Outsourcing today and discover the benefits of
          our flexible, cost-effective solutions.
        </p>
      </div>

      <div id='contact' className='contact'>
        <h2>Contact us</h2>
        <p>
          To learn more about Apto Outsourcing Services, please contact us at:
        </p>
        <p>
          Email:{" "}
          <a className='highlighted' href='mailto: outsource@apto3d.com'>
            outsource@apto3d.com
          </a>
        </p>
        <p>Let Apto Studio bring your vision to life. Contact us today!</p>
      </div>
    </div>
  );
};

export default Outsourcing;
