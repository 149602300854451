import React, { useState } from "react";
import { Link } from "react-scroll";
import logo from "../../../assets/pics/logo.png";
import PrimaryButton from "../../atoms/PrimaryButton";
import "./style.scss";

const Navigation = (props) => {
  const [open, setOpen] = useState(false);

  const openModal = () => {
    setOpen(!open);
  };

  return (
    <div id='Navigation'>
      <div className='logo'>
        <Link to='hero' spy={true} smooth={true} duration={700} offset={-150}>
          <img src={logo} alt='logo' />
        </Link>
      </div>
      <div className='links'>
        <span>
          <PrimaryButton white={true} reactLink='/' label='Home' blue={true} />
        </span>
        <span>
          <PrimaryButton
            reactLink='/solutions'
            label='Solutions'
            blueGradient={true}
          />
        </span>
        <span>
          <PrimaryButton
            blueGradient={true}
            reactLink='/outsourcing'
            label='Outsourcing'
          />
        </span>
        <span>
          <Link
            className='link'
            to='offers'
            spy={true}
            smooth={true}
            offset={-50}
            duration={700}
          >
            We offer
          </Link>
        </span>
        <span>
          <Link
            className='link'
            to='need'
            spy={true}
            smooth={true}
            offset={-70}
            duration={700}
          >
            Why you need us
          </Link>
        </span>
        <span>
          <Link
            className='link'
            to='prices'
            spy={true}
            smooth={true}
            offset={-100}
            duration={700}
          >
            Pricing
          </Link>
        </span>
        <span>
          <Link
            className='link'
            to='contact'
            spy={true}
            smooth={true}
            duration={700}
          >
            Contact us
          </Link>
        </span>
      </div>
      <div className='rightSpace'>
        <div className='hamburger' onClick={() => openModal()}>
          <div className='hamburgerItem' />
          <div className='hamburgerItem' />
          <div className='hamburgerItem' />
        </div>
        {open && (
          <div className='navList'>
            <span onClick={() => setOpen(false)} className='close'>
              Close
            </span>
            <span>
              <PrimaryButton blue={true} reactLink='/' label='Home' />
            </span>
            <span>
              <PrimaryButton
                reactLink='/solutions'
                blueGradient={true}
                label='Solutions'
              />
            </span>
            <span>
              <PrimaryButton
                blueGradient={true}
                reactLink='/outsourcing'
                label='Outsourcing'
              />
            </span>
            <span>
              <Link
                onClick={() => setOpen(false)}
                className='link'
                to='offers'
                spy={true}
                smooth={true}
                offset={-50} //
                duration={700}
              >
                We offer
              </Link>
            </span>
            <span>
              <Link
                onClick={() => setOpen(false)}
                className='link'
                to='need'
                spy={true}
                smooth={true}
                offset={-70}
                duration={700}
              >
                Why you need us
              </Link>
            </span>
            <span>
              <Link
                onClick={() => setOpen(false)}
                className='link'
                to='prices'
                spy={true}
                smooth={true}
                offset={-100}
                duration={700}
              >
                Pricing
              </Link>
            </span>
            <span>
              <Link
                onClick={() => setOpen(false)}
                className='link'
                to='contact'
                spy={true}
                smooth={true}
                duration={700}
              >
                Contact us
              </Link>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navigation;
