import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navigation from "./components/organisms/Navigation";
import RedSofa from "./components/organisms/Red_Sofa";
import BlueSofa from "./components/organisms/Blue_Sofa";
import PaulSofaBeigeFabricBeigeFabricBlack from "./components/organisms/PaulSofa_BeigeFabric_BeigeFabric_Black";
import PaulSofaBeigeFabricBeigeFabricChrome from "./components/organisms/PaulSofa_BeigeFabric_BeigeFabric_Chrome";
import PaulSofaBeigeFabricBlackChrome from "./components/organisms/PaulSofa_BeigeFabric_Black_Chrome";
import PaulSofaBeigeFabricBlackBlack from "./components/organisms/PaulSofa_BeigeFabric_Black_Black";
import PaulSofaVelvetGreenVelvetGreenGold from "./components/organisms/PaulSofa_VelvetGreen_VelvetGreen_Gold";
import PaulSofaVelvetGreenVelvetGreenBlack from "./components/organisms/PaulSofa_VelvetGreen_VelvetGreen_Black";
import PaulSofaVelvetGreenOrangeGold from "./components/organisms/PaulSofa_VelvetGreen_Orange_Gold";
import PaulSofaVelvetGreenOrangeBlack from "./components/organisms/PaulSofa_VelvetGreen_Orange_Black";
import PaulSofaVelvetGreenBlackGold from "./components/organisms/PaulSofa_VelvetGreen_Black_Gold";
import PaulSofaVelvetGreenBlackBlack from "./components/organisms/PaulSofa_VelvetGreen_Black_Black";
import PaulSofaCashmereCashmereChrome from "./components/organisms/PaulSofa_Cashmere_Cashmere_Chrome";
import PaulSofaCashmereCashmereBrass from "./components/organisms/PaulSofa_Cashmere_Cashmere_Brass";
import PaulSofaCashmereCashmereBlack from "./components/organisms/PaulSofa_Cashmere_Cashmere_Black";
import PaulSofaCashmereBlackChrome from "./components/organisms/PaulSofa_Cashmere_Black_Chrome";
import PaulSofaCashmereBlackBlack from "./components/organisms/PaulSofa_Cashmere_Black_Black";
import PaulSofaCashmereBlackBrass from "./components/organisms/PaulSofa_Cashmere_Black_Brass";
import Home from "./components/pages/Home";
import Solutions from "./components/pages/Solutions";
import Outsourcing from "./components/pages/Outsourcing";
import NavigationSolutions from "./components/organisms/NavigationSolutions";
import NavigationOutsourcing from "./components/organisms/NavigationOutsourcing";
import "./styles/index.scss";
import NavigationVirtualExperience from "./components/organisms/NavigationVirtualExperience";
import VirtualExperience from "./components/pages/VirtualExperience";

function App() {
  const [open, setOpen] = useState(false);
  const [openedModal, setOpenedModal] = useState("");
  const [mouseIsDown, setMouseIsDown] = useState(false);
  const [model3D, setModel3D] = useState("blue");

  window.addEventListener("mousedown", function () {
    setMouseIsDown(true);
  });

  window.addEventListener("mouseup", function () {
    setMouseIsDown(false);
  });

  const openModal = (val) => {
    if (val) {
      setModel3D(val);
      setOpen(!open);
    } else {
      setOpen(!open);
    }
  };

  const getModalDetails = (selectedModalItem) => {
    if (!selectedModalItem) {
      return {
        fabric: "-",
        piping: "-",
        leg: "-",
      };
    }
    if (selectedModalItem === "Red_Sofa") {
      return {
        fabric: "Red",
        piping: "Red",
        leg: "Black",
      };
    }
    if (selectedModalItem === "Blue_Sofa") {
      return {
        fabric: "Blue",
        piping: "Blue",
        leg: "Black",
      };
    }
    return selectedModalItem.split("_").reduce((acc, val, i) => {
      if (i === 1) {
        acc.fabric = val;
      }
      if (i === 2) {
        acc.piping = val;
      }
      if (i === 3) {
        acc.leg = val;
      }
      return acc;
    }, {});
  };

  return (
    <div id='App'>
      {open && openedModal === "Red_Sofa" && (
        <RedSofa
          closeModal={openModal}
          mouseIsDown={mouseIsDown}
          setOpenedModal={setOpenedModal}
          openedModal={openedModal}
          getModalDetails={getModalDetails}
        />
      )}
      {open && openedModal === "Blue_Sofa" && (
        <BlueSofa
          closeModal={openModal}
          mouseIsDown={mouseIsDown}
          setOpenedModal={setOpenedModal}
          openedModal={openedModal}
          getModalDetails={getModalDetails}
        />
      )}
      {open && openedModal === "PaulSofa_BeigeFabric_BeigeFabric_Black" && (
        <PaulSofaBeigeFabricBeigeFabricBlack
          closeModal={openModal}
          mouseIsDown={mouseIsDown}
          setOpenedModal={setOpenedModal}
          openedModal={openedModal}
          getModalDetails={getModalDetails}
        />
      )}
      {open && openedModal === "PaulSofa_BeigeFabric_BeigeFabric_Chrome" && (
        <PaulSofaBeigeFabricBeigeFabricChrome
          closeModal={openModal}
          mouseIsDown={mouseIsDown}
          setOpenedModal={setOpenedModal}
          openedModal={openedModal}
          getModalDetails={getModalDetails}
        />
      )}
      {open && openedModal === "PaulSofa_BeigeFabric_Black_Chrome" && (
        <PaulSofaBeigeFabricBlackChrome
          closeModal={openModal}
          mouseIsDown={mouseIsDown}
          setOpenedModal={setOpenedModal}
          openedModal={openedModal}
          getModalDetails={getModalDetails}
        />
      )}
      {open && openedModal === "PaulSofa_BeigeFabric_Black_Black" && (
        <PaulSofaBeigeFabricBlackBlack
          closeModal={openModal}
          mouseIsDown={mouseIsDown}
          setOpenedModal={setOpenedModal}
          openedModal={openedModal}
          getModalDetails={getModalDetails}
        />
      )}
      {open && openedModal === "PaulSofa_VelvetGreen_VelvetGreen_Gold" && (
        <PaulSofaVelvetGreenVelvetGreenGold
          closeModal={openModal}
          mouseIsDown={mouseIsDown}
          setOpenedModal={setOpenedModal}
          openedModal={openedModal}
          getModalDetails={getModalDetails}
        />
      )}
      {open && openedModal === "PaulSofa_VelvetGreen_VelvetGreen_Black" && (
        <PaulSofaVelvetGreenVelvetGreenBlack
          closeModal={openModal}
          mouseIsDown={mouseIsDown}
          setOpenedModal={setOpenedModal}
          openedModal={openedModal}
          getModalDetails={getModalDetails}
        />
      )}
      {open && openedModal === "PaulSofa_VelvetGreen_Orange_Gold" && (
        <PaulSofaVelvetGreenOrangeGold
          closeModal={openModal}
          mouseIsDown={mouseIsDown}
          setOpenedModal={setOpenedModal}
          openedModal={openedModal}
          getModalDetails={getModalDetails}
        />
      )}
      {open && openedModal === "PaulSofa_VelvetGreen_Orange_Black" && (
        <PaulSofaVelvetGreenOrangeBlack
          closeModal={openModal}
          mouseIsDown={mouseIsDown}
          setOpenedModal={setOpenedModal}
          openedModal={openedModal}
          getModalDetails={getModalDetails}
        />
      )}
      {open && openedModal === "PaulSofa_VelvetGreen_Black_Gold" && (
        <PaulSofaVelvetGreenBlackGold
          closeModal={openModal}
          mouseIsDown={mouseIsDown}
          setOpenedModal={setOpenedModal}
          openedModal={openedModal}
          getModalDetails={getModalDetails}
        />
      )}
      {open && openedModal === "PaulSofa_VelvetGreen_Black_Black" && (
        <PaulSofaVelvetGreenBlackBlack
          closeModal={openModal}
          mouseIsDown={mouseIsDown}
          setOpenedModal={setOpenedModal}
          openedModal={openedModal}
          getModalDetails={getModalDetails}
        />
      )}
      {open && openedModal === "PaulSofa_Cashmere_Cashmere_Chrome" && (
        <PaulSofaCashmereCashmereChrome
          closeModal={openModal}
          mouseIsDown={mouseIsDown}
          setOpenedModal={setOpenedModal}
          openedModal={openedModal}
          getModalDetails={getModalDetails}
        />
      )}
      {open && openedModal === "PaulSofa_Cashmere_Cashmere_Brass" && (
        <PaulSofaCashmereCashmereBrass
          closeModal={openModal}
          mouseIsDown={mouseIsDown}
          setOpenedModal={setOpenedModal}
          openedModal={openedModal}
          getModalDetails={getModalDetails}
        />
      )}
      {open && openedModal === "PaulSofa_Cashmere_Cashmere_Black" && (
        <PaulSofaCashmereCashmereBlack
          closeModal={openModal}
          mouseIsDown={mouseIsDown}
          setOpenedModal={setOpenedModal}
          openedModal={openedModal}
          getModalDetails={getModalDetails}
        />
      )}
      {open && openedModal === "PaulSofa_Cashmere_Black_Chrome" && (
        <PaulSofaCashmereBlackChrome
          closeModal={openModal}
          mouseIsDown={mouseIsDown}
          setOpenedModal={setOpenedModal}
          openedModal={openedModal}
          getModalDetails={getModalDetails}
        />
      )}
      {open && openedModal === "PaulSofa_Cashmere_Black_Black" && (
        <PaulSofaCashmereBlackBlack
          closeModal={openModal}
          mouseIsDown={mouseIsDown}
          setOpenedModal={setOpenedModal}
          openedModal={openedModal}
          getModalDetails={getModalDetails}
        />
      )}
      {open && openedModal === "PaulSofa_Cashmere_Black_Brass" && (
        <PaulSofaCashmereBlackBrass
          closeModal={openModal}
          mouseIsDown={mouseIsDown}
          setOpenedModal={setOpenedModal}
          openedModal={openedModal}
          getModalDetails={getModalDetails}
        />
      )}
      <Router>
        <Routes>
          <Route
            exact
            path='/'
            element={
              <>
                <NavigationVirtualExperience />
                <VirtualExperience />
              </>
            }
          />
          <Route
            exact
            path='/solutions'
            element={
              <>
                <NavigationSolutions />
                <Solutions />
              </>
            }
          />
          <Route
            exact
            path='/outsourcing'
            element={
              <>
                <NavigationOutsourcing />
                <Outsourcing />
              </>
            }
          />
          <Route
            exact
            path='/product-visualization'
            element={
              <>
                <Navigation />
                <Home
                  setModel3D={setModel3D}
                  openModal={openModal}
                  setOpenedModal={setOpenedModal}
                  model3D={model3D}
                />
              </>
            }
          />
          <Route
            exact
            path='/*'
            element={
              <>
                {/* <Navigation /> */}
                <NavigationVirtualExperience />
                <VirtualExperience />
                {/* <Home
                  setModel3D={setModel3D}
                  openModal={openModal}
                  setOpenedModal={setOpenedModal}
                  model3D={model3D}
                /> */}
              </>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
