import React, { useState } from "react";
import { Link } from "react-scroll";
import logo from "../../../assets/pics/logo.png";
import PrimaryButton from "../../atoms/PrimaryButton";
import "./style.scss";

const NavigationSolutions = (props) => {
  const [open, setOpen] = useState(false);

  const openModal = () => {
    setOpen(!open);
  };

  return (
    <div id='NavigationSolutions'>
      <div className='logo'>
        <Link
          to='product_configurator'
          spy={true}
          smooth={true}
          duration={700}
          offset={-150}
        >
          <img src={logo} alt='logo' />
        </Link>
      </div>
      <div className='links'>
        <span>
          <PrimaryButton reactLink='/' label='Home' blue={true} />
        </span>
        <span>
          <PrimaryButton
            blueGradient={true}
            reactLink='/outsourcing'
            label='Outsourcing'
          />
        </span>
        <span>
          <PrimaryButton
            blueGradient={true}
            reactLink='/product-visualization'
            label='Product Visualization'
          />
        </span>
        <span>
          <Link
            className='link'
            to='product_configurator'
            spy={true}
            smooth={true}
            offset={-50}
            duration={700}
          >
            3D Product Configurator
          </Link>
        </span>
        <span>
          <Link
            className='link'
            to='room_scenes'
            spy={true}
            smooth={true}
            offset={-70}
            duration={700}
          >
            Room Scenes
          </Link>
        </span>
        <span>
          <Link
            className='link'
            to='ar'
            spy={true}
            smooth={true}
            offset={-100}
            duration={700}
          >
            Augmented Reality
          </Link>
        </span>
        <span>
          <Link
            className='link'
            to='pricing'
            spy={true}
            smooth={true}
            offset={-100}
            duration={700}
          >
            Pricing
          </Link>
        </span>
        <span>
          <Link
            className='link'
            to='contact'
            spy={true}
            smooth={true}
            duration={700}
          >
            Contact us
          </Link>
        </span>
      </div>
      <div className='rightSpace'>
        <div className='hamburger' onClick={() => openModal()}>
          <div className='hamburgerItem' />
          <div className='hamburgerItem' />
          <div className='hamburgerItem' />
        </div>
        {open && (
          <div className='navList'>
            <span onClick={() => setOpen(false)} className='close'>
              Close
            </span>
            <span>
              <PrimaryButton reactLink='/' label='Home' blue={true} />
            </span>
            <span>
              <PrimaryButton
                blueGradient={true}
                reactLink='/outsourcing'
                label='Outsourcing'
              />
            </span>
            <span>
              <PrimaryButton
                blueGradient={true}
                reactLink='/product-visualization'
                label='Product Visualization'
              />
            </span>
            <span>
              <Link
                onClick={() => setOpen(false)}
                className='link'
                to='product_configurator'
                spy={true}
                smooth={true}
                offset={-50}
                duration={700}
              >
                3D Product Configurator
              </Link>
            </span>
            <span>
              <Link
                onClick={() => setOpen(false)}
                className='link'
                to='room_scenes'
                spy={true}
                smooth={true}
                offset={-70}
                duration={700}
              >
                Room Scenes
              </Link>
            </span>
            <span>
              <Link
                onClick={() => setOpen(false)}
                className='link'
                to='ar'
                spy={true}
                smooth={true}
                offset={-100}
                duration={700}
              >
                Augmented Reality
              </Link>
            </span>
            <span>
              <Link
                onClick={() => setOpen(false)}
                className='link'
                to='pricing'
                spy={true}
                smooth={true}
                offset={-100}
                duration={700}
              >
                Pricing
              </Link>
            </span>
            <span>
              <Link
                onClick={() => setOpen(false)}
                className='link'
                to='contact'
                spy={true}
                smooth={true}
                duration={700}
              >
                Contact us
              </Link>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavigationSolutions;
