import React from "react";
import PrimaryButton from "../../atoms/PrimaryButton";
import kithenConfigurator from "../../../assets/pics/solutions/Apto_Kitchen_Configurator_HR.jpg";
import chairFront from "../../../assets/pics/solutions/Devon-Dining-Chair_Silos_LR1.jpg";
import chairCloseUp from "../../../assets/pics/solutions/Devon-Dining-Chair_ZoomIn_LR.jpg";
import diningRoom from "../../../assets/pics/solutions/Devon_dining_chair_LS_LR.jpg";
import arKitchen from "../../../assets/pics/solutions/AR_Kitchen.jpg";
import livingRoom from "../../../assets/pics/solutions/Living_Option_lR.jpg";
import sink3d from "../../../assets/pics/solutions/360-viewer-configurator-Kitchen-Mixer-GIF.gif";
import roomBanner from "../../../assets/pics/solutions/Room_scene_banner.jpg";
import cutOut from "../../../assets/pics/solutions/Bathroom_GIF.gif";
import sink from "../../../assets/pics/solutions/Bathroom-Vanity-GIF.gif";
import chairGif from "../../../assets/pics/solutions/Flexform-Alison-Armchair-360-Spin.gif";
import arBanner from "../../../assets/pics/solutions/AR_banner.png";
import chairBanner from "../../../assets/pics/solutions/Dining_Chair_Configurator_Banner.png";
import arRadiator from "../../../assets/pics/solutions/AR_Radiator_GIF.gif";
import arQR from "../../../assets/pics/solutions/AR_QR_Code.png";
import bathroomConfigurator from "../../../assets/pics/solutions/360-viewer-configurator--Bathroom.jpg";
import expand from "../../../assets/pics/solutions/Tumbnails/expand.png";
import smartphone from "../../../assets/pics/solutions/Tumbnails/smartphone.png";
import wallet from "../../../assets/pics/solutions/Tumbnails/wallet.png";
import slider from "../../../assets/pics/solutions/Tumbnails/slider.png";
import dollarSymbol from "../../../assets/pics/solutions/Tumbnails/dollar-symbol.png";
import refresh from "../../../assets/pics/solutions/Tumbnails/refresh.png";
import pantone from "../../../assets/pics/solutions/Tumbnails/pantone.png";
import dimensions from "../../../assets/pics/solutions/Tumbnails/dimensions.png";
import degree360rotateIcon from "../../../assets/pics/solutions/Tumbnails/360-degree-rotate-icon.png";

import "./style.scss";

const Solutions = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id='Solutions'>
      <div id='product_configurator' className='bannerImgWhite'>
        <img src={chairBanner} alt='..' />
        <h1>3D product configurator</h1>
        <p>Allows you to show any product, from any angle, in any variation.</p>
        <p>
          Generate top-quality photorealistic product visuals in 3D at any
          scale.
        </p>
      </div>

      <div className='container'>
        <div className='increaseSales'>
          <div className='textBox'>
            <h2>Increase sales by 50% with an interactive 3D configurator.</h2>
            <p className='highlighted'>
              Transform your business with beautiful visual solutions made just
              for you.
            </p>
            <p>
              Allow customers to view products from any angle, rotate them and
              zoom in and out for more detail.
            </p>
          </div>
          <img src={kithenConfigurator} alt='...' />
        </div>
      </div>

      <div className='banner'>
        <h1>Bespoke quality 3D experience with a 360-product configurator.</h1>
      </div>

      <div className='container'>
        <div className='main lighterBg'>
          <div className='left'>
            <h2>Let customers experience your product in a brand-new way.</h2>
            <p>Create as many products variations as you need.</p>
            <div className='checkmarks'>
              <p className='highlighted'>
                <i className='fa-solid fa-check checks'></i>
                <span>Different color choices</span>
              </p>
              <p className='highlighted'>
                <i className='fa-solid fa-check checks'></i>
                <span>Various materials to choose from</span>
              </p>
              <p className='highlighted'>
                <i className='fa-solid fa-check checks'></i>
                <span>Countless patterns available</span>
              </p>
              <p className='highlighted'>
                <i className='fa-solid fa-check checks'></i>
                <span>Endless design variations</span>
              </p>
            </div>
          </div>
          <div className='right'>
            <img draggable='false' src={chairGif} alt='' />
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='prices2'>
          <h2>Create one 3d model, reuse it forever.</h2>
          <p>
            Having pleasing aesthetics on your website - same product
            specifications, angles and resolution instantly increases revenue.
          </p>
          <p>
            Create brand consistency and benefit by reusing the same 3d model
            across different customer touchpoints.
          </p>
          <div className='pricesBoxes'>
            <div className='priceBox'>
              <p className='priceBoxPs1'>
                <i className='fa-solid fa-check checks'></i>
                <span>In product catalogs / brochures</span>
              </p>
              <p className='priceBoxPs1'>
                <i className='fa-solid fa-check checks'></i>
                <span>For campaign shots</span>
              </p>
            </div>
            <div className='priceBox'>
              <p className='priceBoxPs2'>
                <i className='fa-solid fa-check checks'></i>
                <span>For advertising</span>
              </p>
              <p className='priceBoxPs2'>
                <i className='fa-solid fa-check checks'></i>
                <span>For leaflets</span>
              </p>
            </div>
            <div className='priceBox'>
              <p className='priceBoxPs3'>
                <i className='fa-solid fa-check checks'></i>
                <span>Used on social media</span>
              </p>
              <p className='priceBoxPs3'>
                <i className='fa-solid fa-check checks'></i>
                <span>On your website</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='prices'>
          <h2>
            Interactive 3d models will change the way customers see your
            products and brand.
          </h2>
          <div className='pricesBoxes'>
            <div className='priceBox'>
              <img src={wallet} alt='..' />
              <p>
                <span className='highlighted'>Cheaper than photography</span>
                <br />
                With 3D product visualization, prices start at 50€, while the
                price of a photoshoot with all the necessary equipment costs
                roughly 5000€, and gives you no flexibility to make changes
                later on.
              </p>
            </div>
            <div className='priceBox'>
              <img src={smartphone} alt='..' />
              <p>
                <span className='highlighted'>
                  Fully digital and stress-free
                </span>
                <br />
                All you need to do is send us the reference images for your
                product, no need for shipping, renting, hiring, lighting,
                staging, buying props for getting product shots.
              </p>
            </div>
            <div className='priceBox'>
              <img src={expand} alt='..' />
              <p>
                <span className='highlighted'>
                  Easily scalable and flexible
                </span>
                <br />
                Product photographs are outdated due to their inability to
                change for each purpose, while our 3D visuals are like working
                files - easily adaptable, and become more cost-effective with
                each use.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='increaseSales darkerBg'>
          <div className='textBox'>
            <h2>Let customers configure your products in a brand-new way.</h2>
          </div>
          <img src={bathroomConfigurator} alt='...' />
        </div>
      </div>
      <div className='container'>
        <div className='textWithBtn'>
          <div className='left'>
            <p>
              Book a free consultation with our team to discuss your business’s
              needs for your e-commerce store and see why 3D and AR product
              visualizations are a great fit for your company.
            </p>
          </div>
          <div className='right'>
            <PrimaryButton
              className='aaa'
              label='Get started'
              hrefTo='mailto: info@apto3d.com'
            />
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='main lighterBg'>
          <div className='left'>
            <h2>
              Get people talking about your product with virtual photography.
            </h2>
            <p>
              Much cheaper than traditional product photos, 3D virtual
              photography allows you to reimagine digital marketing.
            </p>
            <PrimaryButton
              label='Request a consultation'
              hrefTo='mailto: info@apto3d.com'
              className='moveLeft'
            />
          </div>
          <div className='right'>
            <img draggable='false' src={sink} alt='' />
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='main'>
          <div className='left'>
            <h2>
              Transform one 3D model into a vast product image library. It’s
              that simple.
            </h2>
            <p>
              Modern day customers expect to see at least 6 images per product
              before making a purchase.
            </p>
            <p>
              Instead of outdated photography with staging and lighting for
              every product, you can:
            </p>
            <div className='checkmarks'>
              <p className='highlighted'>
                <i className='fa-solid fa-check checks'></i>
                <span>
                  Produce THOUSANDS of product images from just one 3D model.
                </span>
              </p>
              <p className='highlighted'>
                <i className='fa-solid fa-check checks'></i>
                <span>Place in any space or angle.</span>
              </p>
            </div>
          </div>
          <div className='right'>
            <img draggable='false' src={cutOut} alt='' />
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='prices'>
          <h2>Virtual photography is a gamechanger.</h2>
          <div className='pricesBoxes'>
            <div className='priceBox'>
              <img src={slider} alt='..' />
              <p>
                <span className='highlighted'>Flexibility</span>
                <br />
                <br />
                We can create limitless product variations form one 3D model by
                changing design parameters (color, size, material, finish...)
              </p>
            </div>
            <div className='priceBox'>
              <img src={dollarSymbol} alt='..' />
              <p>
                <span className='highlighted'>Cost efficiency</span>
                <br />
                <br />
                Computer generated images are about 10x cheaper to create and
                can completely replace old-school photoshoots.
              </p>
            </div>
            <div className='priceBox'>
              <img src={refresh} alt='..' />
              <p>
                <span className='highlighted'>Versatility</span>
                <br />
                <br />
                Easily reuse 3D renders in configurators, 3D viewers, AR and
                room scenes.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='main'>
          <div className='left'>
            <h2>eCommerce strategy reshaped by 3D & AR.</h2>
            <p>
              Schedule a meeting with an Apto specialist to get started right
              away.
            </p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <PrimaryButton
              label='Schedule a meeting'
              hrefTo='mailto: info@apto3d.com'
              className='moveLeft'
            />
          </div>
          <div className='right'>
            <img draggable='false' src={sink3d} alt='' />
          </div>
        </div>
      </div>

      <div id='room_scenes' className='bannerImg noMarginUp'>
        <img src={roomBanner} alt='..' />
        <h1>Room Scenes</h1>
        <p>
          Increase customer engagement and up your sales using high quality
          visuals.
        </p>
      </div>

      <div className='container'>
        <div className='main lighterBg'>
          <div className='left'>
            <h2>Place 3D models of your products in CUSTOM MADE interiors.</h2>
            <p>
              Experience unlimited options. <br /> Use your products in visually
              enticing room scenes that showcase the product in different
              settings and types of interior/exterior design. <br /> Limit you
              expenses while still appealing to all tastes and budgets.
            </p>
          </div>
          <div className='right'>
            <img draggable='false' src={livingRoom} alt='' />
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='prices darkerBg'>
          <h2>Room scenes brought to life.</h2>
          <div className='pricesBoxes'>
            <div className='priceBox'>
              <img src={pantone} alt='..' />
              <p>
                <span className='highlighted'>Get the mood right</span>
                <br />
                <br />
                Send us a mood board showing the color palette,
                exterior/interior style and type of design elements you want us
                to use.
              </p>
            </div>
            <div className='priceBox'>
              <img src={dimensions} alt='..' />
              <p>
                <span className='highlighted'>Choose the angle</span>
                <br />
                <br />
                Specify the product feature you want customers to notice and we
                will provide you with the best angle as well as lighting and
                composition.
              </p>
            </div>
            <div className='priceBox'>
              <img src={degree360rotateIcon} alt='..' />
              <p>
                <span className='highlighted'>Experience the Apto magic</span>
                <br />
                <br />
                Highly trained experts turn all the data from your brief into
                amazing, photorealistic room scenes and make your product stand
                out.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='prices3'>
          <h2>Meet customer expectations.</h2>
          <div className='pricesBoxes'>
            <div className='priceBox'>
              <img src={chairFront} alt='..' />
              <p>Silos</p>
            </div>
            <div className='priceBox'>
              <img src={chairCloseUp} alt='..' />
              <p>Close-up</p>
            </div>
            <div className='priceBox'>
              <img src={diningRoom} alt='..' />
              <p>Room scene</p>
            </div>
          </div>
          <p className='subtext'>*We can fulfill any brief or requirement.</p>
        </div>
      </div>

      <div id='ar' className='bannerImgWhite noMarginUp'>
        <img src={arBanner} alt='..' />
        <h1>Augmented Reality</h1>
        <p>
          Provide captivating shopping experience via web and mobile browsers.
          Just one click away.
        </p>
      </div>

      <div className='container'>
        <div className='main lighterBg'>
          <div className='left'>
            <h2>Enhance online shopping experience with augmented reality.</h2>
            <p>
              Give customers a chance to interact with your products before
              making a purchase. <br /> AR enables shoppers to see if the
              product is the right fit for them size and style-wise, thus
              reducing product returns.
            </p>
            <p>All done in real-time helping speed up the selection process.</p>
          </div>
          <div className='right'>
            <img draggable='false' src={arKitchen} alt='' />
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='main'>
          <div className='left'>
            <h2>Augmented Reality – the key to customer empowerment.</h2>
            <p>
              Implementing the Apto 3D viewer is straightforward. <br /> We
              provide you with the code which is simple to copy – paste into the
              backend of your e-commerce platform, so your customers can view
              the product in their space.
            </p>
            <div className='checkmarks'>
              <p className='highlighted'>
                <i className='fa-solid fa-check checks'></i> True to size
              </p>
              <p className='highlighted'>
                <i className='fa-solid fa-check checks'></i>No app or coding
                needed
              </p>
              <p className='highlighted'>
                <i className='fa-solid fa-check checks'></i> Works on all
                devices
              </p>
            </div>
          </div>
          <div className='right'>
            <img draggable='false' src={arRadiator} alt='' />
          </div>
        </div>
      </div>

      <div className='banner noMarginUp'>
        <h1>Set the bar high for the business metaverse.</h1>
      </div>

      <div className='container'>
        <div className='twoBoxes'>
          <div className='left'>
            <h2>Highlight your product’s special features.</h2>
            <p>
              Use 3D product models flawlessly across all platforms and customer
              touchpoints – applied to virtual showrooms and configurators,
              product pages and marketing channels.
            </p>
          </div>
          <div className='right'>
            <h2>AR answers some of the most frequently asked questions.</h2>
            <div className='checkmarks'>
              <p className='line'>
                <i className='fa-solid fa-check checks'></i>{" "}
                <span>What does the back of this product look like?</span>
              </p>
              <p className='line'>
                <i className='fa-solid fa-check checks'></i>{" "}
                <span>How big is it?</span>
              </p>
              <p className='line'>
                <i className='fa-solid fa-check checks'></i>{" "}
                <span>Is it true to the picture?</span>
              </p>
            </div>
            <p>Just scan the QR code and find out!</p>
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='main'>
          <div className='left'>
            <h2>
              Use QR codes to improve digital and print product experience.
            </h2>
            <p>
              Every smartphone camera supports AR Technology, enabling shoppers
              to see and interact with products in augmented reality by scanning
              a QR code.
            </p>
            <p className='highlighted'>
              QR codes make products appear in real life at no additional cost
              by printing them on:
            </p>
            <div className='checkmarks'>
              <p className='highlighted'>
                <i className='fa-solid fa-check checks'></i> Brochures
              </p>
              <p className='highlighted'>
                <i className='fa-solid fa-check checks'></i>Leaflets needed
              </p>
              <p className='highlighted'>
                <i className='fa-solid fa-check checks'></i> Packaging devices
              </p>
            </div>
          </div>
          <div className='right'>
            <img draggable='false' src={arQR} alt='' />
          </div>
        </div>
      </div>

      <div className='container'>
        <div id='pricing' className='title'>
          <h1>Apto Pricing</h1>
          <p>
            We create custom pricing based on your specific needs and budget.
          </p>
        </div>
      </div>

      <div className='container'>
        <div className='prices'>
          <div className='priceText'>
            <div className='left'>
              <h2>
                Best-in-class product experiences with 3D and Augmented Reality
              </h2>
              <p>Have questions about pricing?</p>
              <a className='blued' href='mailto: info@apto3d.com'>
                Chat with an Apto specialist {">"}
              </a>
            </div>
            <div className='right'>
              <PrimaryButton
                className='aaa'
                label={"Get pricing"}
                hrefTo='mailto: info@apto3d.com'
              />
            </div>
          </div>
          <h2>The best way to get pricing:</h2>
          <div className='pricesBoxes'>
            <div className='priceBox'>
              <p className='titlePrice'>1</p>
              <p>
                <span className='highlighted'>Schedule a meeting with our</span>
                <br />
                <span className='highlighted'>specialists.</span>
                <br />
                We will help you decide what is the <br />
                best fit for your business needs.
              </p>
            </div>
            <div className='priceBox'>
              <p className='titlePrice'>2</p>
              <p>
                <span className='highlighted'>
                  Fill out Apto process survey.
                </span>
                <br />
                So we can have all the specifics –
                <br />
                amount of products, type of <br />
                visualization, time and budget.
              </p>
            </div>
            <div className='priceBox'>
              <p className='titlePrice'>3</p>
              <p>
                <span className='highlighted'>Get the quote.</span>
                <br />
                You will receive a pricing presentation
                <br />
                to review with all the costs clearly <br />
                explained and decide if you need to <br />
                make any changes.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='container'>
        <div id='contact' className='contactUs'>
          <h1>Contact us</h1>
          <p>
            Contact us via{" "}
            <a className='highlighted' href='mailto: info@apto3d.com'>
              info@apto3d.com
            </a>{" "}
            and we will get back to you to schedule a meeting and discuss how we
            can bring your products to life.
          </p>
        </div>
      </div>
      <div className='container'>
        <div className='footer'>
          <p>Apto 2022. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default Solutions;
